import React from 'react'
import { motion } from 'framer-motion'
import LineImage from './LineImage'
import BulbImage from './BulbImage'


const Career = () => {

    const titleButton = {
        hidden: { opacity: 0 },
        show: {
            x: 20,
            opacity: 1,
            transition: {
                duration: 1,
                scale: 1.4,
                staggerChildren: 0.1,
                delayChildren: 0.3,
            },
        },
    }

    const textAnimation = {
        hidden: {
            opacity: 0,
            y: 50,
        },
        show: {
            opacity: 1,
            y: 0
        }
    }

    const headingAnimation = {
        hidden: {
            opacity: 0,
            // y: 50,
        },
        show: {
            opacity: 1,
            // y: 0
        }
    }

    const itemB = {
        hidden: { scale: 0, top: 200 },
        show: { scale: 1, top: 80 },
    }


    return (
        <div className="career_banner">
            <div className="wrapper">
                <div className="career_detail">
                    <div className="left_col">
                        <div className="title">
                            <motion.a
                                href="#"
                                className="  button-container-1"
                                variants={titleButton}
                                initial="hidden"
                                animate="show"
                            >
                                <span
                                    className="mas"
                                >
                                    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z" fill="#09C2C1" />
                                    </svg> Career
                                </span>
                                <span className="mask_btn">
                                    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z" fill="white" />
                                    </svg> Career
                                </span>
                            </motion.a>
                            <motion.h1
                                variants={textAnimation}
                                initial="hidden"
                                animate="show"
                                transition={{
                                    delay: 1,
                                    duration: 1,
                                    // delayChildren: 0.3,
                                }}
                            >
                                Innovators Wanted Join the Codeflix Web Team and
                                <motion.span
                                    variants={headingAnimation}
                                    initial="hidden"
                                    animate="show"
                                    transition={{
                                        delay: 2,
                                        duration: 2
                                    }}
                                >
                                    &nbsp; Create the Future !
                                </motion.span>
                            </motion.h1>

                        </div>
                    </div>
                    <div className="right_col">
                        <div className="bubble_wrap">
                            <div className="bubble_img">
                                <img src="./assets/images/new/bubble_2.svg" alt="" />
                            </div>
                            <div
                                className="image"
                            >
                                <BulbImage />
                            </div>
                        </div>
                    </div>
                </div>
                <LineImage />
            </div>
            {/* <img  src={} alt="Line 2" />, */}
        </div>
    )
}

export default Career
