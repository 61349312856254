import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from './Logo';
import Animate from './Animate';

const Header = () => {


    const [isHeaderFixed, setHeaderFixed] = useState(false);
    const [isButtonMenuActive, setButtonMenuActive] = useState(false);
    const [isMenuListActive, setMenuListActive] = useState(false);
    const [ipAddress, setIpAddress] = useState('');

    const navigate = useNavigate();
    let location = useLocation();

    // const getData = async () => {
    //     const res = await axios.get("https://api.ipify.org/?format=json");
    //     const formData = new FormData();
    //     formData.append("url", location.pathname)
    //     formData.append("ip", res.data.ip)

    //     visitStore(formData)
    // };

    useEffect(() => {
        window.scrollTo(0, 0);
        // getData();
    }, [location.pathname])

    useEffect(() => {


        const handleScroll = () => {
            const scroll = window.scrollY;
            if (scroll >= 100) {
                setHeaderFixed(true);
            } else {
                setHeaderFixed(false);
            }
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Empty dependency array for componentDidMount

    const NavbarList = [
        { url: 'home', name: 'Home', hasChild: false },
        {
            url: 'service', name: 'Services', hasChild: true, child: [
                { url: 'service', name: 'Web Development' },
                { url: 'service', name: 'UI / UX' },
                { url: 'service', name: 'Custom PHP Development' },
                { url: 'service', name: 'Mobile App Development' },
                { url: 'service', name: 'Search Engine Optimization' },
            ]
        },
        { url: 'about', name: 'About Us', hasChild: false },
        { url: 'our-work', name: 'Career', hasChild: false },
        { url: 'contact-us', name: 'Contact Us', hasChild: false }
    ];

    const isServiceHeader = location.pathname == '/service';
    let headerClass = isHeaderFixed ? 'fixed' : '';
    headerClass = isServiceHeader ? `${headerClass} white_header` : headerClass;

    const handleButtonMenuClick = () => {
        setButtonMenuActive(!isButtonMenuActive);
        setMenuListActive(!isMenuListActive);
    };

    return (
        <header className={headerClass}>
            <div className="wrapper">
                <a onClick={() => navigate('/')} className="logo">
                    <Logo />
                </a>
                <nav>
                    <ul className={`menu-list ${isMenuListActive ? 'active' : ''}`}>
                        {NavbarList.map((item, index) => (
                            <>
                                <li key={index}>
                                    <a class={location.pathname === `/${item.url}` ? 'active' : ''} onClick={() => navigate(item.url)}>{item.name}</a>
                                    {item.hasChild &&
                                        <ul class="submenu">
                                            {item.child.map((subItem) => (
                                                <li><a onClick={() => navigate(item.url)}>{subItem.name}</a></li>
                                                // <li><a href="#">Ui / Ux</a></li>
                                                // <li><a href="#">Costom PHP Development</a></li>
                                                // <li><a href="#">Mobile App Development</a></li>
                                                // <li><a href="#">Search Engine Optimization</a></li>
                                            ))
                                            }
                                        </ul>
                                    }
                                </li>
                            </>
                        ))}
                    </ul>
                    <button className={`button_menu ${isButtonMenuActive ? 'active' : ''}`} onClick={handleButtonMenuClick}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </button>
                </nav>
            </div>
        </header>
    )
}

export default Header
