import React from 'react'
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const WorkBenefit = () => {

        const [ref, inView] = useInView({
            triggerOnce: true, // Ensures the animation occurs only once
            threshold: 0.2, // Change as needed; it's the percentage of the element's visibility to trigger the animation
        });

    const item1 = {
        hidden: { y: 200, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 0.5,
                // fade: 'fadeIn'
            }
        }
    };
   
    const item2 = {
        hidden: { y: 200, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                delay: 0.5,
                duration: 0.5,
                // fade: 'fadeIn'
            }
        }
    };
    const item3 = {
        hidden: { y: 200, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                delay: 1,
                duration: 0.5,
                // fade: 'fadeIn'
            }
        }
    };
    const item4 = {
        hidden: { y: 200, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                delay: 1.5,
                duration: 0.5,
                // fade: 'fadeIn'
            }
        }
    };

    return (
        <div className="work_benifit">
            <div className="wrapper">
                <div className="left_col">
                    <div className="benifit_list" ref={ref}>
                        <motion.div
                            initial="hidden"
                            animate={inView ? 'visible' : 'hidden'}
                            variants={item1}
                            className="benifit_col">
                            <img src="./assets/images/new/b1.png" data-aos="zoom-out-up" alt="" />
                            <h3 data-aos="zoom-out-up">Expanding Horizons</h3>
                            <p data-aos="zoom-out-up">Opportunities for cross-functional learning.</p>
                        </motion.div>
                        <motion.div
                            initial="hidden"
                            animate={inView ? 'visible' : 'hidden'}
                            variants={item2} className="benifit_col">
                            <img src="./assets/images/new/b2.png" data-aos="zoom-out-up" alt="" />
                            <h3 data-aos="zoom-out-up">Project Engagement</h3>
                            <p data-aos="zoom-out-up">Direct involvement in impactful projects.</p>
                        </motion.div>
                        <motion.div
                            initial="hidden"
                            animate={inView ? 'visible' : 'hidden'}
                            variants={item3}  className="benifit_col">
                            <img data-aos="zoom-out-up" src="./assets/images/new/b3.png" alt="" />
                            <h3 data-aos="zoom-out-up">Tailored Growth</h3>
                            <p data-aos="zoom-out-up">Personalized career growth plans.</p>
                        </motion.div>
                        <motion.div
                            initial="hidden"
                            animate={inView ? 'visible' : 'hidden'}
                            variants={item4}  
                            className="benifit_col">
                            <img src="./assets/images/new/b4.png" data-aos="zoom-out-up" alt="" />
                            <h3 data-aos="zoom-out-up">Work Flexibility</h3>
                            <p data-aos="zoom-out-up">Flexibility in work arrangements.</p>
                        </motion.div>
                    </div>
                </div>
                <div className="right_col">
                    <div className="title">
                        <a data-aos="zoom-out-up" href="#" className="  button-container-1">
                            <span className="mas">
                                <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z" fill="#09C2C1" />
                                </svg> Benefits
                            </span>
                            <span className="mask_btn">
                                <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z" fill="white" />
                                </svg> Benefits
                            </span>
                        </a>
                        <h2 data-aos="zoom-out-up">Perks of Working with us</h2>
                        <p data-aos="zoom-out-up">Codeflix Web offers an array of exceptional perks for its team. Enjoy flexible work arrangements, continuous skill development, and a vibrant culture that champions creativity and collaboration. Elevate your career with us and experience the advantages of a dynamic and enriching professional journey.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WorkBenefit
