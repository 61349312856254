import React, { useEffect, useState } from 'react'
import Banner from '../component/Banner'
import HomeAbout from '../component/HomeAbout'
import Work from '../component/Work'
import FeaturedWork from '../component/FeaturedWork'
import Trusted from '../component/Trusted'
import Testimonial from '../component/Testimonial'
import InnoSection from '../component/InnoSection'
import Blog from '../component/Blog'

const Home = () => {

  useEffect(() => {
    // setLoading(true);
  },[])

  return (
    <>
      <Banner />
      <HomeAbout />
      <Work />
      <FeaturedWork />
      <Trusted />
      <Testimonial />
      <InnoSection />
      <Blog />  
    </>

  )
}

export default Home
