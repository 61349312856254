import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer';

const HomeAbout = () => {

  const [ref, inView] = useInView({
    triggerOnce: true, // Ensures the animation occurs only once
    threshold: 0.2, // Change as needed; it's the percentage of the element's visibility to trigger the animation
  });

  const leftAnimation = {
    hidden: { opacity: 0, x: -1000 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.9, ease: 'easeOut' }, // Define your desired animation properties
    },
  }
  const leftInnerAnimation = {
    hidden: { opacity: 0, x: -1000 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.9, ease: 'easeOut' }, // Define your desired animation properties
    },
  }

  const TitleAnimation = {
    hidden: { opacity: 0, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 3, ease: 'easeOut', delay: 0.5 }, // Define your desired animation properties
    },
  }

  const image2Animation = {
    hidden: { opacity: 0, scale: 0, x: -200 },
    visible: {
      opacity: 1,
      scale: 1,
      x: 0,
      transition: { duration: 1, ease: 'easeOut', delay: 1 }, // Define your desired animation properties
    },
  }

  const image3Animation = {
    hidden: { opacity: 0, scale: 0, x: -200 },
    visible: {
      opacity: 1,
      scale: 1,
      x: 0,
      transition: { duration: 1, ease: 'easeOut', delay: 1.5 }, // Define your desired animation properties
    },
  }


  return (
    <div className="home_about" ref={ref}>
      <div className="wrapper">
        <motion.div
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          variants={leftAnimation}

          className="home_about_detail">
          <motion.div
            className="left_col"
          >
            <div
              className="image"
              style={{
                backgroundImage: "url(assets/images/new/about_bg1.png)",
              }}
            >
              <img
                src="./assets/images/new/aboutimages.png" alt="" />
            </div>
            <div className="about_favi"></div>
          </motion.div>
          <motion.div
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
            variants={leftInnerAnimation}
            className="right_col" >
            <div className="title home_title">
              <Link to='/about'>
                <a
                  data-aos="zoom-out-up"
                  href="#"
                  className="  button-container-1"
                >
                  <span className="mas">
                    <svg
                      width="15"
                      height="10"
                      viewBox="0 0 15 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z"
                        fill="#09C2C1"
                      />
                    </svg>
                    About Us
                  </span>
                  <span className="mask_btn">
                    <svg
                      width="15"
                      height="10"
                      viewBox="0 0 15 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z"
                        fill="white"
                      />
                    </svg>
                    About Us
                  </span>
                </a>
              </Link>
              <h2>
                Let Us Be Your <br />
                <motion.span
                  initial="hidden"
                  animate={inView ? 'visible' : 'hidden'}
                  variants={TitleAnimation}
                >
                  IT Partner
                </motion.span>
              </h2>

              <p data-aos="zoom-out-up">
                In an increasingly digital world, navigating the realm of information technology can be complex. That's where we come in. Let us be your trusted IT partner, providing the experience and insight necessary to empower your business.
              </p>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </div>
  )
}

export default HomeAbout
