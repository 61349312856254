import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Header from "./component/Header";
import Footer from "./component/Footer";
import { useEffect, useState } from "react";
import Aos from "aos";
import Home from "./pages/Home";
import Service from "./pages/Service";
import About from "./pages/About";
import ContactUs from "./component/ContactUs";
import OurWork from "./pages/OurWork";
import { ToastContainer, toast } from 'react-toast'

function App() {
  useEffect(() => {

    Aos.init({
      duration: 1000,
    });

    const blob = document.querySelector("#blob");
    const body = document.querySelector("body");

    const handleMouseMove = (e) => {
      let half = blob.offsetWidth / 2;
      blob.style.left = `${e.clientX - half}px`;
      blob.style.top = `${e.clientY - half}px`;
    };

    body.addEventListener("mousemove", handleMouseMove);

    return () => {
      body.removeEventListener("mousemove", handleMouseMove);
    };

    toast("Thank you for contacting us, we will get in thouch soon.", {
      backgroundColor: "#323131",
      color: "#ffffff"
    });
  }, []);

  return (
    <Router>
      <div id="full_wrapper" className="home_page">
        <div id="overlay">
          <ToastContainer position="bottom-right" delay={6000}/>
        </div>
        <div id="blob"></div>
        <Header />
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/home" element={<Home/>} />
          <Route path="/service" element={<Service/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/our-work" element={<OurWork/>} />
        </Routes>
        <ContactUs />
        {/* <ToastContainer delay={8000} /> */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
