import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import './Testimonial.css'; // Import your CSS file for styling (if needed)
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

const Testimonial = () => {

  const [clients,  setClients] = useState([]);

  useEffect(() => {
    // getClients().then((res) => {
    //   setClients(res.data.result);
    // })
  },[])
  console.log(clients);

  
  const clientLogoList = [
    './assets/images/site/company1.png',
    './assets/images/site/company3.png',
    './assets/images/site/company4.png',
    './assets/images/site/company5.png',
    './assets/images/site/company6.png',
    './assets/images/site/company7.png',
    './assets/images/site/company8.png',
    './assets/images/site/company9.png',
  ];

  // const clienList = [
  //   {
  //     image: './assets/images/site/client1.png',
  //     position: 'Lorem Ipsum',
  //     companyName: 'Company Name',
  //     description: 'Vorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia',
  //   },
  //   {
  //     image: './assets/images/site/client1.png',
  //     position: 'Lorem Ipsum',
  //     companyName: 'Company Name',
  //     description: 'Vorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia',
  //   },
  //   {
  //     image: './assets/images/site/client1.png',
  //     position: 'Lorem Ipsum',
  //     companyName: 'Company Name',
  //     description: 'Vorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia',
  //   },
  //   {
  //     image: './assets/images/site/client1.png',
  //     position: 'Lorem Ipsum',
  //     companyName: 'Company Name',
  //     description: 'Vorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia',
  //   },
  //   {
  //     image: './assets/images/site/client1.png',
  //     position: 'Lorem Ipsum',
  //     companyName: 'Company Name',
  //     description: 'Vorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia',
  //   },
  //   // Add more items as needed
  // ];

  const settings = {
    slidesToShow: 2,
    slidesToScroll: 1,
    draggable: true,
    arrows: false,
    className: 'client_list',
    focusOnSelect: true,
    variableWidth: true,
    adaptiveHeight: false,
    prevArrow: 
      <div className="slickbtn slick-prev">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.41394 8.00003L13.9999 8.00003L13.9999 6.00003L4.41394 6.00003L8.70694 1.70703L7.29294 0.293032L0.585938 7.00003L7.29294 13.707L8.70694 12.293L4.41394 8.00003Z" fill="black"/></svg>
      </div>
    ,
    nextArrow: 
      <div className="slickbtn slick-next">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.58606 5.99997L6.2693e-05 5.99997L6.26056e-05 7.99997L9.58606 7.99997L5.29306 12.293L6.70706 13.707L13.4141 6.99997L6.70706 0.292968L5.29306 1.70697L9.58606 5.99997Z" fill="black"/></svg>
      </div>
    ,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
    ],
  };

  return (
    <div className="testimonial">
      <div className="wrapper">
        <div className="testimonial_wrap">
          <div className="left_col">
            <div className="title">
              <a data-aos="zoom-out-up" className="button-container-1">
                <span className="mas">
                <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z" fill="#fff"></path></svg>
                  Testimonial
                </span>
                <span className="mask_btn">
                  <svg
                    width="15"
                    height="10"
                    viewBox="0 0 15 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    {/* Your SVG path for the title button mask */}
                  </svg>
                  Testimonial
                </span>
              </a>
              <h2 data-aos="zoom-out-up">
                They <span>Trust Us</span>
              </h2>
              <p data-aos="zoom-out-up">
              The foundation of our success is built upon the trust our clients place in us. Their confidence in our services and expertise is a testament to the strong and enduring partnerships we've cultivated.
              </p>
            </div>
          </div>
          <div className="right_col">
            <div className="client_logo_list">
              {clientLogoList.map((item, index) => (
                <div className="image" data-aos="zoom-out-up" key={index}>
                  <img src={item} alt="" />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="client_slider">
          <Slider {...settings}>
            {clients.map((item, index) => (
              <div className="client_item" key={index}>
                <div className="client_col">
                  <div className="detail">
                    <div className="icon">
                      <img src="./assets/images/site/quote.svg" alt="" />
                    </div>
                    <div className="client_position">
                      <div className="c_company_name">
                        <span className="position">
                          {item.position}
                        </span>
                        <span className="company_name">
                          {item.companyName}
                        </span>
                      </div>
                      <div className="c_image">
                        <img src={item.image} alt="" />
                      </div>
                    </div>
                    <p>{item.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
