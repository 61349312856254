import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import React from 'react'
import { Link } from 'react-router-dom'

const Work = () => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Ensures the animation occurs only once
    threshold: 0.2, // Change as needed; it's the percentage of the element's visibility to trigger the animation
  });

  const workList = [
    { image: './assets/images/new/mobile.svg', title: 'Mobile Application', description: 'Mastering Mobile App Development for Seamless User Experiences' },
    { image: './assets/images/new/worldwideweb.svg', title: 'Website Development', description: 'Crafting interactive and user-centric websites to enhance online presence' },
    { image: './assets/images/new/design.svg', title: 'UI / UX Design', description: 'Designing user-friendly interfaces for seamless interactions' },
    { image: './assets/images/new/meeting.svg', title: 'Resource Allocation', description: "We're here as your committed partner, providing dedicated support for your progress." },
    { image: './assets/images/new/technicalsupport.svg', title: 'Maintenance', description: "Tailored Maintenance Services to Safeguard and Elevate Your Website's Functionality" },
  ]

  const item1 = {
    hidden: { y: 500, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 1,
        // fade: 'fadeIn'
      }
    }
  };


  return (
    <div className="at_Work" ref={ref}>
      <div className="wrapper">
        <div className="title home_title">
          <Link to='/service'>
            <a data-aos="zoom-out-up" href="#" className="  button-container-1">
              <span className="mas">
                <svg
                  width="15"
                  height="10"
                  viewBox="0 0 15 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z"
                    fill="#09C2C1"
                  />
                </svg>
                Services
              </span>
              <span className="mask_btn">
                <svg
                  width="15"
                  height="10"
                  viewBox="0 0 15 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z"
                    fill="#fff"
                  />
                </svg>
                Services
              </span>
            </a>
          </Link>
          <h2 data-aos="zoom-out-up">
            We’re Creative <span>At Work</span>
          </h2>
          <p data-aos="zoom-out-up">
            At the heart of our work is a commitment to creative excellence. We believe in infusing innovation and imagination into every service we provide.
          </p>
        </div>
        <motion.div
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          variants={item1}
          className="at_work_list"
        >
          {workList.map((item, index) => (

            <div key={index} className="at_work_col">
              <div className="image_wrap" data-aos="zoom-out-up">
                <div className="image">
                  <img src={item.image} alt="" />
                </div>
              </div>
              <h3 data-aos="zoom-out-up">{item.title}</h3>
              <p data-aos="zoom-out-up">
                {item.description}
              </p>
            </div>

          ))}

        </motion.div>
      </div>
    </div>
  )
}

export default Work
