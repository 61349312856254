import React from 'react'

const Footer = () => {
  return (
    <footer>
    <div className="wrapper">
      <div className="footer_row">
        <div className="copy_text">©Concept Design Codeflix Web LLP</div>
        <div className="social_link">
          <a href="#">
            <img src="./assets/imagesite/facebook.svg" alt="" />
          </a>
          <a href="#">
            <img src="./assets/images/site/Linkedin.svg" alt="" />
          </a>
          <a href="#">
            <img src="./assets/images/site/Instagram.svg" alt="" />
          </a>
        </div>
      </div>
    </div>
  </footer>
  )
}

export default Footer
