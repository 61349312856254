import React from 'react'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer';


const CustomNeed = () => {
    const [ref, inView] = useInView({
        triggerOnce: true, // Ensures the animation occurs only once
        threshold: 0.2, // Change as needed; it's the percentage of the element's visibility to trigger the animation
    });

    const leftAnimation = {
        hidden: { opacity: 0, scale: 0, x: -200 },
        visible: {
            opacity: 1,
            scale: 1,
            x: 0,
            transition: { duration: 1, ease: 'easeOut' }, // Define your desired animation properties
        },
    }
    
    const image1Animation = {
        hidden: { opacity: 0, scale: 0, x: -200 },
        visible: {
            opacity: 1,
            scale: 1,
            x: 0,
            transition: { duration: 1, ease: 'easeOut', delay: 0.5 }, // Define your desired animation properties
        },
    }
    
    const image2Animation = {
        hidden: { opacity: 0, scale: 0, x: -200 },
        visible: {
            opacity: 1,
            scale: 1,
            x: 0,
            transition: { duration: 1, ease: 'easeOut', delay: 1 }, // Define your desired animation properties
        },
    }
    
    const image3Animation = {
        hidden: { opacity: 0, scale: 0, x: -200 },
        visible: {
            opacity: 1,
            scale: 1,
            x: 0,
            transition: { duration: 1, ease: 'easeOut', delay: 1.5 }, // Define your desired animation properties
        },
    }



    return (
        <div className="custom_need">
            <div className="wrapper" ref={ref}>
                <motion.div
                    initial="hidden"
                    animate={inView ? 'visible' : 'hidden'}
                    variants={leftAnimation}
                    className="left_col">
                    <div className="titles">
                        <a href="javascript:void(0)" className="btn-text">Your Concept, Our Promised Commitment</a>
                        <h3>CUSTOM TO YOUR NEEDS</h3>
                        <hr />
                        <p >Uniting your vision with our expertise, we craft custom software solutions that exceed expectations. From ideation to launch, our dedicated team ensures alignment with your strategic goals in the dynamic digital realm.</p>
                    </div>
                </motion.div>
                <div className="right_col">
                    <div className="image_list">
                        <motion.div
                            initial="hidden"
                            animate={inView ? 'visible' : 'hidden'}
                            variants={image1Animation}
                            className="image">
                            <img src="./assets/images/site/custom1.png" alt="" />
                        </motion.div>
                        <motion.div
                            initial="hidden"
                            animate={inView ? 'visible' : 'hidden'}
                            variants={image3Animation}
                            className="image">
                            <img src="./assets/images/site/custom2.png" alt="" />
                        </motion.div>
                        <motion.div
                            initial="hidden"
                            animate={inView ? 'visible' : 'hidden'}
                            variants={image2Animation}
                            className="image">
                            <img src="./assets/images/site/custom3.png" alt="" />
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomNeed
