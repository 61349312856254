import Spline from '@splinetool/react-spline'
import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'


const Banner = () => {

    const imageAnimation = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 3,
            }
        }
    }
    
    const textAnimation = {
        hidden: {
            scale: 0
        },
        visible: {
            scale: 1,
            transition: {
                duration: 3,
            }
        }
    }
    
    const leftTitleAnimation = {
        hidden: {
            opacity: 0,
            left: 500
        },
        visible: {
            left: 0,
            opacity: 1,
            transition: {
                delay: 3,
                duration: 3,
            }
        }
    }

    const topTextAnimation = {
        hidden: {
            opacity: 0,
            scale: 0
        },
        visible: {
            scale: 1,
            opacity: 1,
            rotate: 360,
            transition: {
                delay: 2.5,
                duration: 3,
                ease: 'easeIn'
            }
        }
    }

    const leftTextAnimation = {
        hidden: {
            opacity: 0,
            scale: 0
        },
        visible: {
            scale: 1,
            opacity: 1,
            rotate: 360,
            transition: {
                delay: 3,
                duration: 3,
                ease: 'easeIn'
            }
        }
    }

    const bottomTextAnimation = {
        hidden: {
            opacity: 0,
            scale: 0
        },
        visible: {
            scale: 1,
            opacity: 1,
            rotate: 360,
            transition: {
                delay: 3.5,
                duration: 3,
                ease: 'easeIn'
            }
        }
    }

    const rightTextAnimation = {
        hidden: {
            opacity: 0,
            scale: 0
        },
        visible: {
            scale: 1,
            opacity: 1,
            rotate: 360,
            transition: {
                delay: 4,
                duration: 3,
                ease: 'easeIn'
            }
        }
    }

    return (
        <div className="home_banner">
            <div className="home_bg_video">
                <video preload="metadata" poster="./assets/images/new/home_banner.png" autoPlay="autoplay" muted playsInline loop>
                    <source src="./assets/images/new/home2.mp4" type="video/mp4" />
                </video>
            </div>
            <div className="wrapper">
                <div className="career_detail">
                    <div className="left_col">
                        <div className="title home_title">
                            <motion.h1
                                variants={textAnimation}
                                initial="hidden"
                                animate="visible"
                            >
                                Your Partner For
                                &nbsp;
                                <motion.span
                                     variants={leftTitleAnimation}
                                     initial="hidden"
                                     animate="visible"
                                
                                >
                                    Software Innovation
                                </motion.span>
                            </motion.h1>

                            <p>Explore the world of innovation with us. We're your partner for every step of the software development journey.</p>
                            <Link to='/contact-us'>
                                <a href="#" className="  button-container-1">

                                    <span className="mas">
                                        Contact Us
                                    </span>
                                    <span className="mask_btn">
                                        Contact Us
                                    </span>
                                </a>

                            </Link>
                        </div>
                    </div>
                    <div className="right_col">
                        <div className="screen_wrap">
                            <motion.div
                                variants={imageAnimation}
                                initial="hidden"
                                animate="visible"
                                className="screen_img">
                                <img src="./assets/images/new/screen.png" alt="" />
                                {/* <Spline scene="https://prod.spline.design/w6599Seg5994Zf6x/scene.splinecode" /> */}
                            </motion.div>
                            <div className="screen_icons">
                                <motion.div
                                    variants={topTextAnimation}
                                    initial="hidden"
                                    animate="visible"
                                    className="icons icons_1"
                                >
                                    <p>App Developement</p>
                                </motion.div>
                                <motion.div
                                    variants={leftTextAnimation}
                                    initial="hidden"
                                    animate="visible"
                                    className="icons icons_2"
                                >
                                    <p>SEO</p>
                                </motion.div>
                                <motion.div
                                    variants={bottomTextAnimation}
                                    initial="hidden"
                                    animate="visible"
                                    className="icons icons_3">
                                    <p>Resource Provider</p>
                                </motion.div>
                                <motion.div
                                    variants={rightTextAnimation}
                                    initial="hidden"
                                    animate="visible"
                                    className="icons icons_4">
                                    <p>UI / UX</p>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner
