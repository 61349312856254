import React from 'react'
import { motion } from 'framer-motion';

const ServiceFlow = () => {
    return (
        <div className="serivces_flow" id="wrap">
            <div className="titles">
                <div className="wrapper">
                    <a href="javascript:void(0)" className="btn-blue">
                    Transforming Ideas into Reality
                    </a>
                    <motion.h3
                    >
                        Technology We Use
                    </motion.h3>
                    <p>
                    Powered by a diverse array of sophisticated tools and innovative platforms, our suite of technology stands at the forefront of industry standards.
                    </p>
                </div>
            </div>
            <div className="wrapper">
                <div className="service_flow_wrap">
                    <svg width="829" height="893" viewBox="0 0 829 893" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g className="css_circle">
                            <g filter="url(#filter0_i_11_54)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M111.588 643.689C108.919 643.895 106.222 644 103.5 644C46.3385 644 0 597.661 0 540.5C0 483.339 46.3385 437 103.5 437C124.039 437 143.181 442.983 159.278 453.301C173.303 462.291 188.739 470 205.398 470H554C602.049 470 641 508.951 641 557C641 605.049 602.049 644 554 644H119C116.503 644 114.031 643.895 111.588 643.689Z" fill="white" data-aos="fade-in" id="fill_svg0" data-aos-delay="800" />
                            </g>
                            <path d="M111.63 643.19L111.589 643.187L111.549 643.19C108.893 643.396 106.209 643.5 103.5 643.5C46.6147 643.5 0.5 597.385 0.5 540.5C0.5 483.615 46.6147 437.5 103.5 437.5C123.941 437.5 142.989 443.454 159.008 453.722C173.052 462.723 188.589 470.5 205.398 470.5H554C601.773 470.5 640.5 509.227 640.5 557C640.5 604.773 601.773 643.5 554 643.5H119C116.517 643.5 114.059 643.395 111.63 643.19Z" className="circle_path" stroke="#00E1DB" data-aos="fade-in" data-aos-delay="1500" />
                            <circle cx="103.5" cy="540.5" r="82.5" fill="url(#paint0_linear_11_54)" data-aos="slide-right" data-aos-duration="1500" />
                            <circle opacity="0.9" cx="103.5" cy="540.5" r="59.1949" fill="white" data-aos="slide-right" data-aos-duration="1500" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M128.294 512.5C128.882 512.5 129.342 513.004 129.29 513.589L124.875 563.05C124.838 563.465 124.548 563.814 124.146 563.925L104.521 569.366C104.346 569.414 104.161 569.414 103.986 569.366L84.4167 563.933C84.0154 563.822 83.7251 563.473 83.6881 563.059L79.2776 513.589C79.2254 513.004 79.6863 512.5 80.2736 512.5H128.294ZM89.6552 535.697L90.2185 541.983H104.284L104.285 541.981V541.983H112.025L111.295 550.134L104.288 552.026L104.284 552.021L104.256 552.029L97.2561 550.138L96.8086 545.126H93.4071H90.4991L91.3796 554.994L104.255 558.569L104.284 558.561L104.285 558.559V558.566L117.17 554.994L117.265 553.933L118.742 537.385L118.895 535.697L120.03 522.976H104.285H104.285H104.263H88.5245L89.0958 529.261H104.285H104.285H113.142L112.57 535.697H104.285V535.699L104.284 535.697H89.6552Z" fill="#0065F4" data-aos="slide-right" data-aos-duration="1500" />
                        </g>
                        <g className="js_circle">
                            <g filter="url(#filter1_i_11_54)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M715.412 860.689C718.081 860.895 720.778 861 723.5 861C780.661 861 827 814.661 827 757.5C827 700.339 780.661 654 723.5 654C702.961 654 683.819 659.983 667.722 670.301C653.697 679.291 638.261 687 621.602 687H273C224.951 687 186 725.951 186 774C186 822.049 224.951 861 273 861H708C710.497 861 712.969 860.895 715.412 860.689Z" fill="white" data-aos="fade-in" id="fill_svg1" data-aos-delay="800" />
                            </g>
                            <path d="M715.37 860.19L715.411 860.187L715.451 860.19C718.107 860.396 720.791 860.5 723.5 860.5C780.385 860.5 826.5 814.385 826.5 757.5C826.5 700.615 780.385 654.5 723.5 654.5C703.059 654.5 684.011 660.454 667.992 670.722C653.948 679.723 638.411 687.5 621.602 687.5H273C225.227 687.5 186.5 726.227 186.5 774C186.5 821.773 225.227 860.5 273 860.5H708C710.483 860.5 712.941 860.395 715.37 860.19Z" stroke="url(#paint1_radial_11_54)" data-aos="fade-in" data-aos-delay="1500" />
                            <circle cx="721.1" cy="759.5" r="82.5" fill="url(#paint2_linear_11_54)" data-aos="slide-left" data-aos-duration="1500" />
                            <circle opacity="0.9" cx="721.5" cy="759.5" r="59.1949" fill="white" data-aos="slide-left" data-aos-duration="1500" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M754 727H692.75V788.25H754V727ZM739.572 778.347C736.733 778.347 735.128 776.866 733.894 774.852L729.217 777.569C730.906 780.908 734.36 783.455 739.705 783.455C745.172 783.455 749.242 780.616 749.242 775.434C749.242 770.627 746.481 768.489 741.591 766.392L740.152 765.776C737.683 764.706 736.613 764.007 736.613 762.281C736.613 760.884 737.682 759.814 739.368 759.814C741.021 759.814 742.085 760.511 743.072 762.281L747.554 759.403C745.658 756.068 743.027 754.795 739.368 754.795C734.228 754.795 730.939 758.081 730.939 762.397C730.939 767.084 733.698 769.3 737.851 771.07L739.29 771.687C741.915 772.835 743.48 773.534 743.48 775.508C743.48 777.155 741.957 778.347 739.572 778.347ZM717.266 778.311C715.289 778.311 714.466 776.955 713.562 775.35L708.877 778.187C710.234 781.059 712.903 783.444 717.511 783.444C722.612 783.444 726.106 780.732 726.106 774.772V755.123H720.35V774.694C720.35 777.571 719.157 778.311 717.266 778.311Z" fill="#FFE81E" data-aos="slide-left" data-aos-duration="1500" />
                        </g>
                        <g className="html_circle">
                            <g filter="url(#filter2_i_11_54)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M717.412 423.689C720.081 423.895 722.778 424 725.5 424C782.661 424 829 377.661 829 320.5C829 263.339 782.661 217 725.5 217C704.961 217 685.819 222.983 669.722 233.301C655.697 242.291 640.261 250 623.602 250H275C226.951 250 188 288.951 188 337C188 385.049 226.951 424 275 424H710C712.497 424 714.969 423.895 717.412 423.689Z" fill="white" data-aos="fade-in" data-aos-delay="800" id="fill_svg2" />
                            </g>
                            <path d="M717.37 423.19L717.411 423.187L717.451 423.19C720.107 423.396 722.791 423.5 725.5 423.5C782.385 423.5 828.5 377.385 828.5 320.5C828.5 263.615 782.385 217.5 725.5 217.5C705.059 217.5 686.011 223.454 669.992 233.722C655.948 242.723 640.411 250.5 623.602 250.5H275C227.227 250.5 188.5 289.227 188.5 337C188.5 384.773 227.227 423.5 275 423.5H710C712.483 423.5 714.941 423.395 717.37 423.19Z" stroke="#00E1DB" data-aos="fade-in" data-aos-delay="1500" />
                            <circle cx="724.5" cy="320.5" r="82.5" fill="url(#paint3_linear_11_54)" data-aos="slide-right" data-aos-duration="1500" />
                            <circle opacity="0.9" cx="724.5" cy="320.5" r="59.1949" fill="white" data-aos="slide-right" data-aos-duration="1500" />
                            <path d="M698.006 293.052L702.014 344.531C702.075 345.304 702.596 345.963 703.332 346.198L724.427 352.911C724.8 353.03 725.2 353.03 725.573 352.911L746.667 346.198C747.404 345.963 747.925 345.304 747.985 344.531L751.994 293.052C752.08 291.946 751.209 291 750.103 291H699.897C698.791 291 697.92 291.946 698.006 293.052ZM741.714 309.025H715.588L716.376 316.323H741.145L739.525 337.129L725 341.752L710.475 337.129L709.628 326.259H717.252V331.591L725.204 333.733L733.108 331.591L733.721 323.093H709.382L707.766 302.342H742.234L741.714 309.025Z" fill="#FC490B" data-aos="slide-right" data-aos-duration="1500" />
                        </g>
                        <path d="M285 11H558C618.199 11 667 59.801 667 120C667 180.199 618.199 229 558 229H272C211.801 229 163 277.801 163 338C163 398.199 211.801 447 272 447H558C618.199 447 667 495.801 667 556C667 616.199 618.199 665 558 665H272C211.801 665 163 713.801 163 774C163 834.199 211.801 883 272 883H545" stroke="url(#paint4_linear_11_54)" id="filling_path" strokeDashOffset="3000" strokeWidth="20" strokeLinecap="round" data-aos="fade-in" />
                        <g className="webflow_circle">
                            <g filter="url(#filter3_i_11_54)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M111.588 206.689C108.919 206.895 106.222 207 103.5 207C46.3385 207 0 160.661 0 103.5C0 46.3385 46.3385 0 103.5 0C124.039 0 143.181 5.98281 159.278 16.3008C173.303 25.2909 188.739 33 205.398 33H554C602.049 33 641 71.9512 641 120C641 168.049 602.049 207 554 207H119C116.503 207 114.031 206.895 111.588 206.689Z" fill="white" data-aos="fade-in" data-aos-delay="800" id="fill_svg3" />
                            </g>
                            <path d="M111.63 206.19L111.589 206.187L111.549 206.19C108.893 206.396 106.209 206.5 103.5 206.5C46.6147 206.5 0.5 160.385 0.5 103.5C0.5 46.6146 46.6147 0.5 103.5 0.5C123.941 0.5 142.989 6.45383 159.008 16.7217C173.052 25.7234 188.589 33.5 205.398 33.5H554C601.773 33.5 640.5 72.2273 640.5 120C640.5 167.773 601.773 206.5 554 206.5H119C116.517 206.5 114.059 206.395 111.63 206.19Z" stroke="#00E1DB" data-aos="fade-in" data-aos-delay="1500" />
                            <circle cx="103.5" cy="103.5" r="82.5" fill="url(#paint5_linear_11_54)" data-aos="slide-left" data-aos-duration="1500" />
                            <circle opacity="0.9" cx="103.5" cy="103.5" r="59.1949" fill="white" data-aos="slide-left" data-aos-duration="1500" />
                            <path d="M106.171 126.684C104.85 118.122 103.587 109.942 102.324 101.764C102.239 101.754 102.155 101.74 102.071 101.729C101.392 103.351 100.732 104.975 100.036 106.591C98.476 110.226 97.1371 113.967 95.2773 117.464C92.4279 122.829 87.8613 126.172 81.1496 126.426C80.9138 126.435 80.6751 126.407 80.288 126.391C77.9538 113.157 75.6195 99.94 73.2038 86.2543C75.1771 86.7686 76.9584 87.0122 78.533 87.6808C83.6846 89.8625 86.5748 93.5979 86.9008 98.9277C87.1977 103.786 87.4858 108.648 87.803 113.905C88.1261 113.217 88.2978 112.887 88.4434 112.546C90.5681 107.47 92.6229 102.368 94.8291 97.3225C96.9305 92.5125 100.08 88.5686 105.781 87.0771C107.103 86.7307 108.506 86.6549 109.993 86.4356C111.268 95.6064 112.508 104.534 113.803 113.84C114.079 113.152 114.242 112.803 114.365 112.44C115.907 107.939 117.406 103.421 118.995 98.9331C120.404 94.9594 122.564 91.4297 126.365 88.9801C128.946 87.3181 131.822 86.5412 135.198 86.5656C134.375 88.4928 133.627 90.2766 132.849 92.0496C129.494 99.7208 126.138 107.395 122.762 115.058C120.625 119.906 117.47 123.901 111.859 125.609C110.095 126.142 108.206 126.31 106.171 126.684Z" fill="#1B91DA" data-aos="slide-left" data-aos-duration="1500" />
                        </g>
                        <defs>
                            <filter id="filter0_i_11_54" x="0" y="437" width="641" height="218" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="11" />
                                <feGaussianBlur stdDeviation="10" />
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_11_54" />
                            </filter>
                            <filter id="filter1_i_11_54" x="186" y="654" width="641" height="218" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="11" />
                                <feGaussianBlur stdDeviation="10" />
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_11_54" />
                            </filter>
                            <filter id="filter2_i_11_54" x="188" y="217" width="641" height="218" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="11" />
                                <feGaussianBlur stdDeviation="10" />
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_11_54" />
                            </filter>
                            <filter id="filter3_i_11_54" x="0" y="0" width="641" height="218" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="11" />
                                <feGaussianBlur stdDeviation="10" />
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_11_54" />
                            </filter>
                            <linearGradient id="paint0_linear_11_54" x1="103.5" y1="458" x2="103.5" y2="623" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#0065F4" />
                                <stop offset="1" stopColor="#0F4798" />
                            </linearGradient>
                            <radialGradient id="paint1_radial_11_54" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(506.5 757.5) rotate(90) scale(103.5 320.5)">
                                <stop stopColor="#FFE81E" />
                                <stop offset="1" stopColor="#C4B214" />
                            </radialGradient>
                            <linearGradient id="paint2_linear_11_54" x1="82.5" y1="0" x2="82.5" y2="165" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#FFE81E" />
                                <stop offset="1" stopColor="#C4B214" />
                            </linearGradient>
                            <linearGradient id="paint3_linear_11_54" x1="724.5" y1="238" x2="724.5" y2="403" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#FC490B" />
                                <stop offset="1" stopColor="#C7471A" />
                            </linearGradient>
                            <linearGradient id="paint4_linear_11_54" x1="415" y1="11" x2="415" y2="883" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#09C2C1" />
                                <stop offset="1" stopColor="#1D85D8" />
                            </linearGradient>
                            <linearGradient id="paint5_linear_11_54" x1="103.5" y1="21" x2="103.5" y2="186" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#1B91DA" />
                                <stop offset="1" stopColor="#0E4D74" />
                            </linearGradient>
                        </defs>
                    </svg>
                    <div className="content_detail">
                        <div className="col_detail">
                            <div data-aos="zoom-in-up" className="icons" data-aos-delay="600">
                                <img src="./assets/images/new/webflow.svg" alt="" />
                            </div>
                            <h4 data-aos="zoom-in-up" data-aos-delay="600">Webflow</h4>
                            <hr data-aos="zoom-in-up" data-aos-delay="600" style={{ borderColor: '#004875' }} />
                            <p data-aos="zoom-in-up" data-aos-delay="600">
                                Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.
                            </p>
                        </div>
                        <div className="col_detail html">
                            <div data-aos="zoom-in-up" className="icons" data-aos-delay="600"><img src="./assets/images/new/html.svg" alt="" /></div>
                            <h4 data-aos="zoom-in-up" data-aos-delay="600">HTML 5</h4>
                            <hr data-aos="zoom-in-up" data-aos-delay="600" style={{ borderColor: '#C7471A' }} />
                            <p data-aos="zoom-in-up" data-aos-delay="600">Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.</p>
                        </div>
                        <div className="col_detail css">
                            <div data-aos="zoom-in-up" className="icons" data-aos-delay="600"><img src="./assets/images/new/css.svg" alt="" /></div>
                            <h4 data-aos="zoom-in-up" data-aos-delay="600">CSS 3</h4>
                            <hr data-aos="zoom-in-up" data-aos-delay="600" style={{ borderColor: '#0F4798' }} />
                            <p data-aos="zoom-in-up" data-aos-delay="600">Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.</p>
                        </div>
                        <div className="col_detail">
                            <div data-aos="zoom-in-up" className="icons" data-aos-delay="600"><img src="./assets/images/new/js.svg" alt="" /></div>
                            <h4 data-aos="zoom-in-up" data-aos-delay="600">Javascript</h4>
                            <hr data-aos="zoom-in-up" data-aos-delay="600" style={{ borderColor: '#C3B214' }} />
                            <p data-aos="zoom-in-up" data-aos-delay="600">Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceFlow
