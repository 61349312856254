import React, { useEffect } from 'react';
import ServiceBanner from '../component/ServiceBanner';
import $ from 'jquery'; // Import jQuery
import ServiceFlow from '../component/ServiceFlow';
import CustomNeed from '../component/CustomNeed';


const Service = () => {
    useEffect(() => {
        const win = window;

        function isElementVisible($el) {
            const viewTop = win.scrollY;
            const viewBottom = viewTop + window.innerHeight;
            const elTop = $el.offset().top;
            const elBottom = elTop + $el.height();
            return elBottom <= viewBottom && elTop >= viewTop;
        }

        function addAnimation($el, delay) {
            setTimeout(() => {
                $el.addClass('come-in');
                $el.attr('class', 'webflow_circle come-in');
            }, delay);
        }

        const allMods1 = Array.from(document.querySelectorAll('.webflow_circle'));
        const allMods2 = Array.from(document.querySelectorAll('.html_circle'));
        const allMods3 = Array.from(document.querySelectorAll('.css_circle'));
        const allMods4 = Array.from(document.querySelectorAll('.js_circle'));

        win.addEventListener('scroll', () => {
            allMods1.forEach((el, i) => {
                const $el = $(el);
                if (isElementVisible($el)) {
                    addAnimation($el, i * 200);
                }
            });

            allMods2.forEach((el, i) => {
                const $el = $(el);
                if (isElementVisible($el)) {
                    addAnimation($el, i * 200);
                }
            });

            allMods3.forEach((el, i) => {
                const $el = $(el);
                if (isElementVisible($el)) {
                    addAnimation($el, i * 200);
                }
            });

            allMods4.forEach((el, i) => {
                const $el = $(el);
                if (isElementVisible($el)) {
                    addAnimation($el, i * 200);
                }
            });
        });

        const handleScroll = () => {
            const offset = window.pageYOffset;
            const wheight = window.innerHeight;
            const html = document.documentElement;
            const docheight = Math.max(
              document.body.scrollHeight,
              document.body.offsetHeight,
              html.clientHeight,
              html.scrollHeight,
              html.offsetHeight
            );
            let progress = (offset / (docheight - wheight)) * 3000;
      
            // Give the line a CSS gradient based on scroll position
            progress = 3000 - progress * 1.6;
      
            // Update the SVG element with the new stroke-dashoffset
            const fillingPath = document.getElementById('filling_path');
            if (fillingPath) {
              fillingPath.setAttribute('stroke-dashoffset', progress);
            }
          };
      
          // Add scroll event listener when the component mounts
          window.addEventListener('scroll', handleScroll);
      
          // Remove the event listener when the component unmounts
          return () => {
            window.removeEventListener('scroll', handleScroll);
          };
    }, []);

    return (
        <>
            <ServiceBanner />
            <ServiceFlow />
            <CustomNeed />
        </>
    );
};

export default Service;
