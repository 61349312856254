import Spline from '@splinetool/react-spline';
import React, { useState, useEffect, useRef } from 'react';

const InnoSection = () => {
  // State variables
  const [transformAmountLeft, setTransformAmountLeft] = useState(0);
  const [transformAmountRight, setTransformAmountRight] = useState(0);

  const innoSectionRef = useRef(null);
  const [isInViewport, setIsInViewport] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (isInViewport) {
        const delta = window.scrollY - lastScrollY;
        lastScrollY = window.scrollY;

        setTransformAmountLeft((prevTransformAmountLeft) => {
          const newAmount = prevTransformAmountLeft + delta * transformSpeed;
          return newAmount;
        });

        setTransformAmountRight((prevTransformAmountRight) => {
          const newAmount = prevTransformAmountRight - delta * transformSpeed;
          return newAmount;
        });
      }
    };

    let animationFrameId;
    const updateTransform = () => {
      setTransformAmountLeft((prevTransformAmountLeft) => {
        const newAmount = prevTransformAmountLeft + transformSpeed;
        return newAmount > scrollingTextWidth ? -scrollingTextWidth : newAmount;
      });

      setTransformAmountRight((prevTransformAmountRight) => {
        const newAmount = prevTransformAmountRight - transformSpeed;
        return newAmount < -scrollingTextWidth ? scrollingTextWidth : newAmount;
      });

      animationFrameId = requestAnimationFrame(updateTransform);
    };

    const startAnimation = () => {
      animationFrameId = requestAnimationFrame(updateTransform);
    };

    const stopAnimation = () => {
      cancelAnimationFrame(animationFrameId);
    };

    window.addEventListener('scroll', handleScroll);
    let lastScrollY = window.scrollY;

    startAnimation();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      stopAnimation();
    };
  }, [isInViewport]);

  // Define constants
  const transformSpeed = 1;
  const scrollingTextWidth = window.innerWidth / 2;

  const handleIntersection = (entries) => {
    const entry = entries[0];
    if (entry.isIntersecting) {
      setIsInViewport(true);
    } else {
      setIsInViewport(false);
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (innoSectionRef.current) {
      observer.observe(innoSectionRef.current);
    }

    return () => {
      if (innoSectionRef.current) {
        observer.unobserve(innoSectionRef.current);
      }
    };
  }, []);

  return (
    <div className="inno_section" ref={innoSectionRef}>
      <div className="services_as">
        <div className="container" speed="5">
          <div className="scrolling-text" style={{ transform: `translateX(${transformAmountLeft}px)` }}>
            <h3 className="scrolling-text-content top">
              Innovate Tomorrow, Today Innovate Tomorrow, Today Innovate Tomorrow, Today Innovate Tomorrow, Today Innovate Tomorrow, Today
            </h3>
          </div>
        </div>
        <div className="container left-to-right" speed="5">
          <div className="scrolling-text" style={{ transform: `translateX(${transformAmountRight}px)` }}>
            <h3 className="scrolling-text-content center">
              Innovate Tomorrow, Today Innovate Tomorrow, Today Innovate Tomorrow, Today Innovate Tomorrow, Today Innovate Tomorrow, Today
            </h3>
          </div>
        </div>
      </div>
      <div className="inno_wrap">
        {/* <div className="laptop">
          <img data-aos="zoom-out-up" src="./assets/images/new/laptop.png" alt="" />
        </div>
        <div className="iphone">
          <img data-aos="zoom-out-up" src="./assets/images/new/phone.png" alt="" />
        </div> */}
        {/* <Spline scene="https://prod.spline.design/5wRvd5LYrVhLms9q/scene.splinecode" /> */}
        {/* <Spline scene="https://prod.spline.design/5wRvd5LYrVhLms9q/scene.splinecode" /> */}
        <Spline scene="https://prod.spline.design/5wRvd5LYrVhLms9q/scene.splinecode" />
      </div>
    </div>
  );
};

export default InnoSection;
