import React from 'react'
import { motion } from 'framer-motion'

const Logo = () => {

    const svgPath0 = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 1,
            }
        }
    }
    const svgPath1 = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                // delay: 1,
                duration: 1,
                ease: 'easeInOut'
            }
        }
    }


    return (
        <svg
            width="157"
            height="68"
            viewBox="0 0 157 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <motion.path
                variants={svgPath0}
                initial="hidden"
                animate="visible"
                d="M22.9414 18.8897L33.8169 0L44.6797 18.8897H22.9414Z"
                fill="#494949"
            />
            <motion.path
                variants={svgPath0}
                initial="hidden"
                animate="visible"
                d="M33.7905 37.6908L44.666 18.8011L55.5288 37.6908H33.7905Z"
                fill="#494949"
            />
            <motion.path
                variants={svgPath0}
                initial="hidden"
                animate="visible"
                d="M55.5288 0L44.666 18.8897L33.7905 0H55.5288Z"
                fill="#333333"
            />
            <motion.path
                variants={svgPath0}
                initial="hidden"
                animate="visible"
                d="M44.6675 18.7758L33.792 37.6654L22.9292 18.7758H44.6675Z"
                fill="#333333"
            />
            <motion.path
                variants={svgPath0}
                initial="hidden"
                animate="visible"
                d="M32.5889 39.5771L21.7261 58.4795L10.8506 39.5771H32.5889Z"
                fill="#08847E"
            />
            <motion.path
                variants={svgPath0}
                initial="hidden"
                animate="visible"
                d="M21.7383 20.6116L10.8755 39.5012L0 20.6116H21.7383Z"
                fill="#08847E"
            />
            <motion.path
                variants={svgPath0}
                initial="hidden"
                animate="visible"
                d="M0 58.4795L10.8755 39.5771L21.7383 58.4795H0Z"
                fill="#09C2C1"
            />
            <motion.path
                variants={svgPath0} 
                initial="hidden"
                animate="visible"
                d="M10.876 39.5268L21.7388 20.6371L32.6143 39.5268H10.876Z" 
                fill="#09C2C1" 
                />
            <motion.path
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{
                    delay: 1,
                    duration: 1,
                    ease: "easeInOut",
                }}
                strokeWidth={3}
                fill="none"
                stroke="#09C2C1"
                d="M49.8729 42.5144H40.39C40.39 42.5144 33.4014 43.0335 33.4014 50.5919C33.4014 58.1503 40.8078 58.6694 40.8078 58.6694H61.6473C61.6473 58.6694 68.4967 58.2516 68.9271 50.5919C68.9271 50.5919 68.9018 48.0471 68.0282 46.4645H64.2553"
            />
            <motion.path
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{
                    delay: 2,
                    duration: 1,
                    ease: "easeInOut",
                }}
                strokeWidth={3}
                // strokeDasharray="0 1"
                fill="none"
                stroke="#09C2C1"
                d="M53.8978 54.6308H50.3528C50.3528 54.6308 48.4917 51.339 49.5932 47.7308C50.6947 44.1225 54.4802 42.7045 56.0374 42.5146C57.5947 42.3247 80.2193 42.5146 80.2193 42.5146C80.2193 42.5146 82.245 42.9071 82.7261 43.4388V47.1231C82.7261 47.1231"
            />
            <motion.path
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{
                    delay: 2,
                    duration: 1,
                    ease: "easeInOut",
                }}
                strokeWidth={3}
                // strokeDasharray="0 1"
                fill="none"
                stroke="#09C2C1"
                // d="M69.6987 46.5025H72.3958V58.7574H79.5361C80.5361 58.7574 84.2965 55.4789 83.9293 50.1234V42.5144H98.3877V45.4137"
                // d="M69.6987 46.5025H72.3955V55.7574H79.5361C79.5361 55.7574 84.2965 55.4789 83.9293 50.1234V42.5144H98.3877V45.4137H86.6766L86.74 50.4273C86.74 50.4273 86.74 54.7572 84.0432 56.7196C84.0432 56.7196 81.853 58.6694 79.6247 58.6694C77.3964 58.6694 69.5215 58.6694 69.5215 58.6694"
                d="M72.3955 45.2025H72.3955V58.7574H79.5361C79.5361 58.7574 86.2965 57.4789 86.9293 50.1234V42.5144H98.3877V45.4137"
            />
            <motion.path
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{
                    delay: 3,
                    duration: 1,
                    ease: "easeInOut",
                }}
                strokeWidth={3}
                d="M97.6282 51.25H89.8291"
                stroke="#09C2C1"
            />
            <motion.path
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{
                    delay: 4,
                    duration: 1,
                    ease: "easeInOut",
                }}
                strokeWidth={3}
                d="M87.9629 57.3133V58.8336H98.396V58.6569H88.9629"
                stroke="#09C2C1"
            />

            <motion.path
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{
                    delay: 1,
                    duration: 1,
                    ease: "easeInOut",
                }}
                strokeWidth={3}
                d="M103.661 60.5267 V 60.6564 V 42.3121 H114.018"
                stroke="#333333"
            />
            <motion.path
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{
                    delay: 1,
                    duration: 1,
                    ease: "easeInOut",
                }}
                strokeWidth={3}
                d="M106.497 51.6173H113.499"
                stroke="#333333"
            />
            <motion.path
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{
                    delay: 2,
                    duration: 1,
                    ease: "easeInOut",
                }}
                strokeWidth={3}
                d="M117.097 40.5272 V59.6569 H129.403"
                stroke="#333333"
            />
            <motion.path
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{
                    delay: 4,
                    duration: 1,
                    ease: "easeInOut",
                }}
                strokeWidth={3}
                d="M 132.63 40.5272 V 61.4202"
                stroke="#333333"
            />
            <motion.path
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{
                    delay: 4,
                    duration: 1,
                    ease: "easeInOut",
                }}
                strokeWidth={3}
                d="M 137.884 40.5272 
                L 146.884 50.5272 
                L 155.884 40.5272 
                L 137.262 60.5272 
                L 146.884 50.5272
                L 155.884 60.5272 
                "
                stroke="#333333"
            />
            <motion.path initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 5, duration: 1, ease: "easeInOut",}}fill-rule="evenodd" clip-rule="evenodd" d="M84.8907 66.9113H60.6709V64.7089H84.8907V66.9113Z" fill="#09C2C1" />
            <motion.path initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 5, duration: 1, ease: "easeInOut",}}fill-rule="evenodd" clip-rule="evenodd" d="M129.382 66.9113H105.15V64.7089H129.382V66.9113Z" fill="#09C2C1" />
            <motion.path initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 5, duration: 1, ease: "easeInOut",}}d="M103.271 65.6201C103.828 65.5189 103.815 64.7466 103.815 64.7466C103.815 63.7464 102.764 63.6704 102.764 63.6704H99.4725H99.4092V67.8484C99.4092 67.8484 102.612 67.8484 102.802 67.8484C102.979 67.8484 103.942 67.5825 103.942 66.7216C103.929 65.8734 103.271 65.6201 103.271 65.6201ZM102.549 67.1268H100.156V64.4174H102.549C102.549 64.4174 103.081 64.4427 103.081 64.9238C103.081 65.3923 102.574 65.4302 102.574 65.4302H100.688V66.0506H102.638C102.638 66.0506 103.144 66.038 103.169 66.5824C103.169 67.1268 102.549 67.1268 102.549 67.1268Z" fill="#333333" />
            <motion.path initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 5, duration: 1, ease: "easeInOut",}}d="M94.4873 63.6831H98.1969V64.4048H95.1963V67.1268H98.1969V67.8611H94.4873V63.6831Z" fill="#333333" />
            <motion.path initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 5, duration: 1, ease: "easeInOut",}}d="M98.0319 65.4175H95.7656V66.0505H98.0319V65.4175Z" fill="#333333" />
            <motion.path initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 5, duration: 1, ease: "easeInOut",}}d="M86.3198 63.6831H87.1681L88.8646 66.8483L89.485 65.7974L88.4088 63.6831H89.3077L90.9283 66.8483L92.6122 63.6831H93.4098L91.3841 67.4306C91.3841 67.4306 91.1689 67.9371 90.8903 67.9371C90.6118 67.9371 90.4345 67.5699 90.4345 67.5699L89.8901 66.4938L89.2191 67.7092C89.2191 67.7092 89.0925 67.9371 88.852 67.9371C88.6114 67.9371 88.4342 67.6206 88.4342 67.6206L86.3198 63.6831Z" fill="#333333" />
        </svg>

    )
}

export default Logo
