import React from 'react'

const Trusted = () => {
    return (
        <div className="trusted">
            <div className="video-container">
                <div className="wrapper">
                    <video preload="metadata" autoPlay muted playsInline loop >
                        <source src="assets/images/new/trusted.mp4" type="video/mp4"/>
                    </video>
                    <div
                        className="trusted_list"
                        // style={{ backgroundImage: "url(./assets/images/site/map.png)" }}
                    >
                        <h3>We’re Globally Trusted</h3>
                        <p>
                            Unwavering Trust: Where Confidence and Reliability Converge.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Trusted
