import React from 'react'

const ServiceBanner = () => {
    return (
        <div class="inner_banner service-banner" style={{backgroundImage: 'url(/assets/images/site/service_bg.png)'}}>
            <div className="wrapper">
                <div className="banner_detail_1">
                    <div className="details-ser">
                        <h1 id="heading_line">WEB DEVELOPMENT</h1>
                        <h2 id="sub_heading1">Reference site about Lorem Ipsum, giving informationon its origins, as well as a random Lipsum generator.</h2>
                    </div>
                    <div className="arrows">
                        <img src="/assets/images/new/arrows-ser.svg" alt="arrows"/>
                    </div>
                    {/* <CardSwiper/> */}
                </div>
            </div>
        </div>
    )
}

export default ServiceBanner
