import React from 'react'
import { motion } from 'framer-motion'

const LineImage = () => {

    const svgPath = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 1,
            }
        }
    }
    const svgPath1 = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                // delay: 1,
                duration: 1,
                ease: 'easeInOut'
            }
        }
    }


    return (
        <svg className='line_image' width="808" height="258" fill="none" xmlns="http://www.w3.org/2000/svg">
            <motion.path
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{
                    delay: 2,
                    duration: 2,
                    ease: "easeInOut",
                }}
                d="M-14 256.708c43.833-60.167 187.7-166 412.5-108s365.667-74.167 408-147.5"
                stroke="url(#paint0_linear_729_442)"
                strokeWidth="2"
            />
            <defs><linearGradient id="paint0_linear_729_442" x1="-90.5" y1="232.208" x2="842" y2="1.208" gradientUnits="userSpaceOnUse">
                <stop stopColor="#1D85D8" />
                <stop offset="1" stopColor="#09C2C1" />
            </linearGradient>
            </defs>
        </svg>

    )
}

export default LineImage
