import React from 'react'
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const CurrentOpening = () => {

    const [ref, inView] = useInView({
        triggerOnce: true, // Ensures the animation occurs only once
        threshold: 0.2, // Change as needed; it's the percentage of the element's visibility to trigger the animation
    });

    const item1 = {
        hidden: { scale: 0, opacity: 0 },
        visible: {
            scale: 1,
            opacity: 1,
            transition: {
                duration: 1,
                fade: 'fadeIn'
            }
        }
    };

    const item2 = {
        hidden: { y: 400, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                delay: 1,
                duration: 1,
                // fade: 'fadeIn'
            }
        }
    };
    const item3 = {
        hidden: { y: 400, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                delay: 1.5,
                duration: 1,
                // fade: 'fadeIn'
            }
        }
    };
    const item4 = {
        hidden: { y: 400, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                delay: 2,
                duration: 1,
                // fade: 'fadeIn'
            }
        }
    };
    const item5 = {
        hidden: { y: 400, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                delay: 2.5,
                duration: 1,
                // fade: 'fadeIn'
            }
        }
    };
    const item6 = {
        hidden: { y: 400, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                delay: 3,
                duration: 1,
                // fade: 'fadeIn'
            }
        }
    };
    const item7 = {
        hidden: { y: 400, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                delay: 3.5,
                duration: 1,
                // fade: 'fadeIn'
            }
        }
    };
    return (
        <div className="current_opening" ref={ref}>
            <div className="wrapper">
                <div className="jobs_wrap">
                    <motion.div
                        initial="hidden"
                        animate={inView ? 'visible' : 'hidden'}
                        variants={item1}
                        className="title"
                    >
                        <a data-aos="zoom-out-up" href="#" className="  button-container-1">
                            <span className="mas">
                                <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z" fill="#09C2C1" />
                                </svg> Jobs
                            </span>
                            <span className="mask_btn">
                                <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z" fill="white" />
                                </svg> Jobs
                            </span>
                        </a>
                        <h2>Current <span>Openings</span></h2>
                        <p>Unlock your potential with us! At Codeflix Web LLP, we're on the lookout for talented individuals to join our dynamic team. Explore our latest job vacancies across various IT disciplines. Take the next step in your career and be part of our innovative journey.</p>
                    </motion.div>
                </div>
                <div className="job_listing">
                    <motion.a
                        initial="hidden"
                        animate={inView ? 'visible' : 'hidden'}
                        variants={item2}
                        href="javascript:void(0)"
                        className="job_col"
                    >
                        <div className="mask">
                            <div className="col">
                                <div className="image">
                                    <img src="./assets/images/new/j11.svg" alt="" />
                                </div>
                                <div className="detail">
                                    <h3>BDE</h3>
                                    <span className="time">Full-time</span>
                                    <ul>
                                        <li>Networking</li>
                                        <li>Consultation</li>
                                        <li>Negotiation</li>
                                        <li>Advisory</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="mask_wrap">
                            <div className="col">
                                <div className="image">
                                    <img src="./assets/images/new/j1.svg" alt="" />
                                </div>
                                <div className="detail">
                                    <h3>BDE</h3>
                                    <span className="time">Full-time</span>
                                    <ul>
                                        <li>Networking</li>
                                        <li>Consultation</li>
                                        <li>Negotiation</li>
                                        <li>Advisory</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </motion.a>
                    <motion.a
                        initial="hidden"
                        animate={inView ? 'visible' : 'hidden'}
                        variants={item3}
                        href="javascript:void(0)"
                        className="job_col">
                        <div className="mask">
                            <div className="col">
                                <div className="image">
                                    <img src="./assets/images/new/j22.svg" alt="" />
                                </div>
                                <div className="detail">
                                    <h3>Front-End Development</h3>
                                    <span className="time">Full-time</span>
                                    <ul>
                                        <li>HTML, CSS, Javascript</li>
                                        <li>Webflow</li>
                                        <li>Wordpress</li>
                                        <li>Locofy Ai</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="mask_wrap">
                            <div className="col">
                                <div className="image">
                                    <img src="./assets/images/new/j2.svg" alt="" />
                                </div>
                                <div className="detail">
                                    <h3>Front-End Development</h3>
                                    <span className="time">Full-time</span>
                                    <ul>
                                        <li>HTML, CSS, Javascript</li>
                                        <li>Webflow</li>
                                        <li>Wordpress</li>
                                        <li>Locofy Ai</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </motion.a>
                    <motion.a
                        initial="hidden"
                        animate={inView ? 'visible' : 'hidden'}
                        variants={item4}
                        href="javascript:void(0)"
                        className="job_col"
                    >
                        <div className="mask">
                            <div className="col">
                                <div className="image">
                                    <img src="./assets/images/new/j33.svg" alt="" />
                                </div>
                                <div className="detail">
                                    <h3>Android Developer</h3>
                                    <span className="time">Full-time</span>
                                    <ul>
                                        <li>Java</li>
                                        <li>Kotlin</li>
                                        <li>React Native</li>
                                        <li>Flutter</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="mask_wrap">
                            <div className="col">
                                <div className="image">
                                    <img src="./assets/images/new/j3.svg" alt="" />
                                </div>
                                <div className="detail">
                                    <h3>Android Developer</h3>
                                    <span className="time">Full-time</span>
                                    <ul>
                                        <li>Java</li>
                                        <li>Kotlin</li>
                                        <li>React Native</li>
                                        <li>Flutter</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </motion.a>
                    <motion.a
                        initial="hidden"
                        animate={inView ? 'visible' : 'hidden'}
                        variants={item5}
                        href="javascript:void(0)"
                        className="job_col"
                    >
                        <div className="mask">
                            <div className="col">
                                <div className="image">
                                    <img src="./assets/images/new/j44.svg" alt="" />
                                </div>
                                <div className="detail">
                                    <h3>Back-End Development</h3>
                                    <span className="time">Full-time</span>
                                    <ul>
                                        <li>PHP</li>
                                        <li>Node</li>
                                        <li>NO SQL DB</li>
                                        <li>SQL DB</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="mask_wrap">
                            <div className="col">
                                <div className="image">
                                    <img src="./assets/images/new/j4.svg" alt="" />
                                </div>
                                <div className="detail">
                                    <h3>Back-End Development</h3>
                                    <span className="time">Full-time</span>
                                    <ul>
                                        <li>PHP</li>
                                        <li>Node</li>
                                        <li>NO SQL DB</li>
                                        <li>SQL DB</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </motion.a>
                    <motion.a
                        initial="hidden"
                        animate={inView ? 'visible' : 'hidden'}
                        variants={item6}
                        href="javascript:void(0)"
                        className="job_col"
                    >
                        <div className="mask">
                            <div className="col">
                                <div className="image">
                                    <img src="./assets/images/new/j11.svg" alt="" />
                                </div>
                                <div className="detail">
                                    <h3>iOS Developer</h3>
                                    <span className="time">Full-time</span>
                                    <ul>
                                        <li>Swift</li>
                                        <li>Objective-C</li>
                                        <li>UIKit</li>
                                        <li>Xcode</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="mask_wrap">
                            <div className="col">
                                <div className="image">
                                    <img src="images/new/j1.svg" alt="" />
                                </div>
                                <div class="detail">
                                    <h3>iOS Developer</h3>
                                    <span className="time">Full-time</span>
                                    <ul>
                                        <li>Swift</li>
                                        <li>Objective-C</li>
                                        <li>UIKit</li>
                                        <li>Xcode</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </motion.a>
                    <motion.a
                        initial="hidden"
                        animate={inView ? 'visible' : 'hidden'}
                        variants={item7}
                        href="javascript:void(0)"
                        className="job_col"
                    >
                        <div className="mask">
                            <div className="col">
                                <div className="image">
                                    <img src="./assets/images/new/j66.svg" alt="" />
                                </div>
                                <div className="detail">
                                    <h3>Human Recruiter</h3>
                                    <span className="time">Full-time</span>
                                    <ul>
                                        <li>Soft Skills</li>
                                        <li>Adaptability</li>
                                        <li>Strategic</li>
                                        <li>Talent Acquisition</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="mask_wrap">
                            <div className="col">
                                <div className="image">
                                    <img src="./assets/images/new/j6.svg" alt="" />
                                </div>
                                <div className="detail">
                                    <h3>Human Recruiter</h3>
                                    <span className="time">Full-time</span>
                                    <ul>
                                        <li>Communication Skills</li>
                                        <li>Adaptability and Flexibility</li>
                                        <li>Strategic</li>
                                        <li>Talent Acquisition</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </motion.a>
                </div>
            </div>
        </div>
    )
}

export default CurrentOpening
