import React, { useEffect, useState } from 'react'

const FeaturedWork = () => {

  const [projects,  setProjects] = useState([]);

  const [activeRow, setActiveRow] = useState(0);

  useEffect(() => {
    // getProjects().then((res) => {
    //   setProjects(res.data.result);
    // })
  },[])

  const handleRowClick = (rowId) => {
    setActiveRow(rowId);
  };

  const featuredWorkList = [
    {
      name: 'EBN',
      categories: [
        {
          name: 'Web Development',
          path: '',
          hasPath: false
        },
        {
          name: 'UI / UX',
          path: '#',
          hasPath: true
        },
      ],
      image: './assets/images/featured_work/ebn.jpg',
      isActive: true
    },
    // {
    //   name: 'Costa Coffee',
    //   categories: [
    //     {
    //       name: 'Printing Kiosk',
    //       path: '',
    //       hasPath: false
    //     },
    //     {
    //       name: 'UI / UX',
    //       path: '#',
    //       hasPath: true
    //     },
    //     {
    //       name: 'Kiosk App',
    //       path: '#',
    //       hasPath: true
    //     },
    //     {
    //       name: 'Web Developement',
    //       path: '#',
    //       hasPath: true
    //     },
    //   ],
    //   image: './assets/images/featured_work/costa-coffee.jpg',
    //   isActive: false
    // },
    {
      name: 'Deals',
      categories: [
        {
          name: 'Web Development',
          path: '',
          hasPath: false
        },
        {
          name: 'UI / UX',
          path: '#',
          hasPath: true
        },
        {
          name: 'Android App',
          path: '#',
          hasPath: true
        },
        {
          name: 'IOS App',
          path: '#',
          hasPath: true
        },
      ],
      image: './assets/images/featured_work/deals.jpg',
      isActive: false
    },
    // {
    //   name: 'Print And Go',
    //   categories: [
    //     {
    //       name: 'Printing Kiosk',
    //       path: '',
    //       hasPath: false
    //     },
    //     {
    //       name: 'UI / UX',
    //       path: '#',
    //       hasPath: true
    //     },
    //     {
    //       name: 'Kiosk App',
    //       path: '#',
    //       hasPath: true
    //     },
    //     {
    //       name: 'Web Developement',
    //       path: '#',
    //       hasPath: true
    //     },
    //   ],
    //   image: './assets/images/featured_work/print-and-go.jpg',
    //   isActive: false
    // },
    {
      name: 'Swiss Crown',
      categories: [
        {
          name: 'Web Development',
          path: '',
          hasPath: false
        },
        {
          name: 'UI / UX',
          path: '#',
          hasPath: true
        },
        {
          name: 'Android App',
          path: '#',
          hasPath: true
        },
      ],
      image: './assets/images/featured_work/swiss-crown.jpg',
      isActive: false
    },
  ]

  return (
    <div className="featured_work">
      <div className="wrapper">
        <div className="title">
          <a data-aos="zoom-out-up" href="#" className="  button-container-1">
            <span className="mas">
              <svg
                width="15"
                height="10"
                viewBox="0 0 15 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z"
                  fill="#09C2C1"
                />
              </svg>
              Featured Work
            </span>
            <span className="mask_btn">
              <svg
                width="15"
                height="10"
                viewBox="0 0 15 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z"
                  fill="#fff"
                />
              </svg>
              Featured Work
            </span>
          </a>
          <h2 data-aos="zoom-out-up">
            Experience <span>Gallery</span>
          </h2>
        </div>
      </div>
      <div className="featured_work_list">
        {featuredWorkList.map((item, index) => {

          return <div key={index} className={`featured_work_row ${activeRow === index ? 'active' : ''}`} onClick={() => handleRowClick(index)}>
            <div className="wrapper">
              <div className="featured_work_col ">
                <div className="left_col">
                  <h3 data-aos="zoom-out-up">{item.name}</h3>
                  <div className="cat_list">
                    {/* <p key={index+'_sub_title'} data-aos="zoom-out-up">{item.sub_title}</p> */}
                    {item.categories.map((categoryItem, index2) => {
                      return !categoryItem.hasPath
                        ?
                        <p key={index+index2} data-aos="zoom-out-up">{categoryItem.name}</p>
                        :
                        <a key={index+index2} data-aos="zoom-out-up" href='#'>
                          {categoryItem.name}
                        </a>
                    })}
                  </div>
                </div>
                <div className="right">
                  <div className="image  ">
                    <img
                      width={609}
                      height={328}
                      data-aos="zoom-out-up"
                      src={item.image}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        })}
      </div>
    </div>
  )
}

export default FeaturedWork
