import React from 'react'

const Blog = () => {

  const blogList = [
    {
      image: './assets/images/site/tech-one.png',
      title: 'iOS Development',
      description: 'We work with customer from conception, architecture and design of the app, development and setting live. We strive for.'
    },
    {
      image: './assets/images/site/tech-two.png',
      title: 'Android Development',
      description: 'Android OS is powering almost 75% of the overall smart-phones and mobile devices. With such a huge user base connected with Android OS, its eviden.'
    },
    {
      image: './assets/images/site/tech-three.png',
      title: 'website development',
      description: 'Crafting captivating digital experiences through innovative design, tailored to elevate your brands online presence.'
    },
  ]

  return (
    <div className="blog_section">
      <div className="wrapper">
        <div className="title home_title">
          <a data-aos="zoom-out-up" href="#" className="  button-container-1">
            <span className="mas">
              <svg
                width="15"
                height="10"
                viewBox="0 0 15 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z"
                  fill="#09C2C1"
                />
              </svg>
              Blogs
            </span>
            <span className="mask_btn">
              <svg
                width="15"
                height="10"
                viewBox="0 0 15 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z"
                  fill="white"
                />
              </svg>
              Blogs
            </span>
          </a>
          <h2 data-aos="zoom-out-up">
            Latest <span>Tech Update</span>
          </h2>
          <p data-aos="zoom-out-up">
            Cutting-Edge Tech Breakthroughs: Stay Updated with the Latest Advancements
          </p>
        </div>
        <div className="blog_list">
          {blogList.map((item, index) => {
            return (
              <div key={index} className="blog_col" data-aos="zoom-out-up">
                <div
                  className="image"
                  style={{
                    backgroundImage: `url(${item.image})`,
                  }}
                ></div>
                <div className="detail">
                  <h3 data-aos="zoom-out-up">{item.title}</h3>
                  <p data-aos="zoom-out-up">
                    {item.description}
                  </p>
                  <a data-aos="zoom-out-up" href="#" className="submit">
                    Submit
                    <img src="./assets/images/site/right-arrow.svg" alt="" />
                  </a>
                </div>
              </div>
            )
          })}
        </div>
        <div className="blog_btn">
          <a href="#" data-aos="zoom-out-up">
            Get More Blog
            <img src="./assets/images/new/bx-right-arrow-alt.svg" alt="" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Blog
